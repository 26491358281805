import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import EmailView from "@/views/EmailView.vue";
import NewsView from "@/views/NewsView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true // Enable props to be passed via router-view
  },
  {
    path: '/email',
    name: 'email',
    component: EmailView,
    props: true // Enable props to be passed via router-view
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView,
    props: true // Enable props to be passed via router-view
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
