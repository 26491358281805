<template>
  <div class="header header-fixed header-logo-center">
    <div class="header-title"><i class="fa fa-music me-2" aria-hidden="true"></i><span>{{ nowPlaying }}</span></div>
  </div>
</template>

<script>
// import axios from "axios";
import axios from "axios";

export default {
  name: "HeaderComponent",
  data() {
    return {
      nowPlaying: '',
    }
  },
  mounted() {

    this.getNowPlaying();

  },
  methods: {
    getNowPlaying() {
      axios.post(process.env.VUE_APP_API_URL + '/nowPlaying', {
        username: process.env.VUE_APP_USERNAME, radio_id: process.env.VUE_APP_USERNAME
      }).then(response => {
        this.nowPlaying = response.data['truncated_rawmeta'];
      })
    }
  }
}
</script>

<style scoped>

</style>