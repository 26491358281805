<template>
    <slot/>
</template>

<script>

export default {
  name: 'MasterLayout',
  props: ['banners']
}
</script>

<style scoped>



.footer-button {
  cursor: pointer;
}
</style>