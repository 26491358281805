<template>
  <div id="footer-bar" class="footer-bar-1">
    <router-link :to="{name: 'home'}"><i class="fa fa-home top-0"></i><span>Home</span></router-link>
    <router-link :to="{name: 'email'}"><i class="fa fa-mail-bulk top-0"></i><span>Email</span></router-link>
    <router-link :to="{name: 'news'}"><i class="fa fa-newspaper top-0"></i><span>News</span></router-link>
    <PlayerComponent/>

    <a href="#" data-menu="menu-settings"><i class="fa fa-cog"></i><span>Ρυθμίσεις</span></a>
  </div>

  <MenuSettingsComponent/>

</template>

<script>

import PlayerComponent from "@/components/PlayerComponent.vue";
import MenuSettingsComponent from "@/components/MenuSettingsComponent.vue";

export default {
  name: "FooterComponent",
  components: {MenuSettingsComponent, PlayerComponent}
}
</script>

<style scoped>

</style>