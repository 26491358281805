<template>
  <div v-if="loading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <HeaderComponent/>

    <FooterComponent/>

    <div class="page-content header-clear-medium">
      <MasterLayout :banners="banners"/>

      <h1>Vue.js WebSocket Client</h1>
      <div v-if="connected">
        <p>{{ serverMessage }}</p>
        <input v-model="message" @keyup.enter="sendMessage" placeholder="Type a message and press Enter" />
        <button @click="sendMessage">Send</button>
      </div>
      <div v-else>
        <p>Connecting...</p>
      </div>

      <router-view :banners="banners"/>

      <div id="ToastMessage" data-bs-dismiss="ToastMessage" data-bs-delay="5000" data-bs-autohide="true"
           class="toast w-auto notification shadow-xl notch-push mt-3">
        <div class="toast-header bg-red-dark border-red-dark">
          <img src="@/assets/images/preload-logo.png" width="50" class="rounded me-2" alt="...">
          <strong class="me-auto color-white">ΠΡΟΒΛΗΜΑ</strong>
          <button type="button" class="ms-2 mb-1 color-white close" data-bs-dismiss="toast" aria-label="Close">
            <span aria-hidden="true"><strong>Κλείσιμο <i class="fa fa-close"></i></strong></span>
          </button>
        </div>
        <div id="app-toast-error-message" class="toast-body bg-red-dark"></div>
      </div>
      <div id="ToastMessage2" data-bs-dismiss="ToastMessage" data-bs-delay="5000" data-bs-autohide="true"
           class="toast w-auto notification shadow-xl notch-push mt-3">
        <div class="toast-header bg-green-dark border-green-dark">
          <img src="@/assets/images/preload-logo.png" width="50" class="rounded me-2" alt="...">
          <strong class="me-auto color-white">ΕΠΙΤΥΧΙΑ</strong>
          <button type="button" class="ms-2 mb-1 color-white close" data-bs-dismiss="toast" aria-label="Close">
            <span aria-hidden="true"><strong>Κλείσιμο <i class="fa fa-close"></i></strong></span>
          </button>
        </div>
        <div id="app-toast-success-message" class="toast-body bg-green-dark"></div>
      </div>
    </div>

  </div>

</template>

<script>

import '@/assets/scripts/bootstrap.min'
import '@/assets/scripts/fp'
import '@/assets/scripts/custom'

import MasterLayout from "@/layouts/MasterLayout.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "axios";


export default {
  components: {FooterComponent, HeaderComponent, MasterLayout},
  data() {
    return {
      loading: true,

      ws: null,
      connected: false,
      message: '',
      serverMessage: '',

      banners: {
        home_top: "",
        home_bottom: "",
        email_top: "",
        email_bottom: "",
      }
    };
  },
  created() {
    // Prevent the pull-to-refresh behavior on mobile browsers
    window.addEventListener('touchstart', this.onTouchStart, { passive: false });
    window.addEventListener('touchmove', this.onTouchMove, { passive: false });
  },
  mounted() {
    this.getBanners();
    this.setupWebSocket();
  },
  beforeUnmount() {
    // Clean up the event listeners when the component is destroyed
    window.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchmove', this.onTouchMove);
  },
  methods: {
    getBanners() {
      axios.post(process.env.VUE_APP_API_URL + '/radios/banners/get', {
        radio_id: process.env.VUE_APP_RADIO_ID
      }, {
        headers: {Accept: 'application/json'}
      }).then((response) => {
        this.stopLoading();
        this.banners.home_top = response.data.home_top[0];
        this.banners.home_bottom = response.data.home_bottom[0];
        this.banners.email_top = response.data.email_top[0];
        this.banners.email_bottom = response.data.email_bottom[0];
      });
    },
    setupWebSocket() {
      // Connect to the WebSocket server
      this.ws = new WebSocket('wss://85.10.212.42:1967');

      this.ws.onopen = () => {
        this.connected = true;
      };

      this.ws.onmessage = (event) => {
        this.serverMessage = event.data;
      };

      this.ws.onclose = () => {
        this.connected = false;
        console.log('Connection closed');
      };
    },
    sendMessage() {
      if (this.message.trim() !== '') {
        this.ws.send(this.message);
        this.message = '';
      }
    },
    handleRouteChange() {
      this.loading = false;
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    onTouchStart(event) {
      // Track the initial touch position
      this.initialY = event.touches[0].clientY;
    },
    onTouchMove(event) {
      const currentY = event.touches[0].clientY;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // Check if the user is at the top of the page and trying to pull down
      if (scrollTop === 0 && currentY > this.initialY) {
        // Prevent the default action (pull-to-refresh)
        event.preventDefault();
      }
    }
  },
  watch: {
    '$route'() {
      this.startLoading();
      this.$nextTick(() => {
        this.handleRouteChange();
      });
    }
  },
}
</script>

<style>
@import "@/assets/styles/bootstrap.css";
@import "@/assets/css/google-fonts.css";
@import "@/assets/styles/highlights/highlight_red.css";
@import "@/assets/fonts/css/fontawesome-all.min.css";
@import "@/assets/styles/style.css";

/* Add global styles here */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}
</style>
