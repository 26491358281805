<template>
  <MasterLayout>
    <h1>EMAIL</h1>
  </MasterLayout>
</template>

<script>
import MasterLayout from "@/layouts/MasterLayout.vue";

export default {
  name: 'EmailView',
  components: {MasterLayout},
}
</script>

