<template>
  <MasterLayout>
    <div class="card card-style">
      <div class="content mb-3">
        <p class="pb-2 text-center">
          <a href="https://gr-net.gr/kataskevi-web-radio-efarmogis.html" target="_blank">
            <img class="img-fluid" src="@/assets/images/iraradio.jpeg" style="max-width:800px;" alt="RADIO LOGO"/>
          </a>
        </p>
      </div>
    </div>
    <button class="btn btn-primary" @click="testChannel"></button>
    <div class="card card-style">
      <div class="content mb-3">
        <p class="pb-2 text-center">
          <a href="https://gr-net.gr" target="_blank">
            <img v-if="banners.home_top" class="img-fluid" :src="banners.home_top" style="max-width:729px;" alt="ADD TOP"/>
            <img v-else class="img-fluid" src="@/assets/images/white-big.svg" style="max-width:729px;" alt="ADD TOP"/>
          </a>
        </p>
        <p class="pb-2 text-center">
          <a href="https://gr-net.gr" target="_blank">
            <img v-if="banners.home_bottom" class="img-fluid" :src="banners.home_bottom" style="max-width:729px;" alt="ADD BOTTOM"/>
            <img v-else class="img-fluid" src="@/assets/images/white-big.svg" style="max-width:729px;" alt="ADD BOTTOM"/>
          </a>
        </p>
      </div>
    </div>
  </MasterLayout>
</template>

<script>
import MasterLayout from "@/layouts/MasterLayout.vue";
import axios from "axios";

export default {
  name: 'HomeView',
  components: {MasterLayout},
  props: {
    banners: {
      type: Object,
      required: true
    }
  },
  methods: {
    testChannel() {
      axios.get(process.env.VUE_APP_API_URL + '/test-event');
    }
  }
}
</script>
<style>
.header-logo-center .header-title {
  width: 100%;
  left: 0;
  margin-left: 0;
  text-align: center;
}

/*
.grecaptcha-badge {
  visibility: hidden;
}
*/
</style>
