<template>
  <audio id="player" ref="player" src="https://centova.gr-net.gr/proxy/webradio/stream" style="width:50px"></audio>
  <a @click="playAudio" class="footer-button" :class="{ 'visually-hidden': isPlaying }">
    <i class="fa fa-play"></i>
    <span>Play</span>
  </a>
  <a @click="pauseAudio" class="footer-button" :class="{ 'visually-hidden': !isPlaying }">
    <i class="fa fa-pause hid"></i>
    <span>Pause</span>
  </a>
  <form style="max-width: 150px; padding-top: 12px;" class="range-slider bottom-15 range-slider-icons">
    <i style="padding-top: 12px;" class="fa fa-range-icon-1 fa-volume-down color-theme"></i>
    <i style="padding-top: 12px;" class="fa fa-range-icon-2 fa-volume-up color-theme"></i>
    <input id="volume_slider" type="range" v-model="volume" @input="updateVolume" class="ios-slider" value="5">
  </form>
</template>

<script>
export default {
  name: "PlayerComponent",
  data() {
    return {
      isPlaying: false,
      volume: 5,
    }
  },
  methods: {
    playAudio() {
      const player = this.$refs.player;
      player.src = '';
      player.load();
      player.src = 'https://centova.gr-net.gr/proxy/webradio/stream';
      player.load();
      player.play();
      this.isPlaying = true;
    },
    pauseAudio() {
      const player = this.$refs.player;
      player.pause();
      this.isPlaying = false;
    },
    updateVolume() {
      const player = this.$refs.player;
      player.volume = this.volume / 100;
    },
  }
}
</script>

<style scoped>

</style>